export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34')
];

export const server_loads = [0];

export const dictionary = {
		"/[lang]/(home)": [22,[2],[3]],
		"/[lang]/account/security": [23,[2,5],[3]],
		"/[lang]/account/settings": [24,[2,5],[3]],
		"/[lang]/account/subscription": [25,[2,5],[3]],
		"/[lang]/apply-as-ngo": [26,[2],[3]],
		"/[lang]/(action)/compress": [7,[2,4],[3]],
		"/[lang]/contact-us": [27,[2],[3]],
		"/[lang]/(action)/convert/excel-to-pdf": [8,[2,4],[3]],
		"/[lang]/(action)/convert/image-to-pdf": [9,[2,4],[3]],
		"/[lang]/(action)/convert/pdf-to-word": [10,[2,4],[3]],
		"/[lang]/(action)/convert/powerpoint-to-pdf": [11,[2,4],[3]],
		"/[lang]/(action)/convert/word-to-pdf": [12,[2,4],[3]],
		"/[lang]/(developers)/developers": [20,[2],[3]],
		"/[lang]/(developers)/developers/api": [21,[2],[3]],
		"/[lang]/download": [28,[2],[3]],
		"/[lang]/(action)/eco": [13,[2,4],[3]],
		"/[lang]/(action)/edit": [14,[2,4],[3]],
		"/[lang]/(action)/html-to-pdf": [15,[2,4],[3]],
		"/[lang]/legal/privacy-policy": [29,[2,6],[3]],
		"/[lang]/legal/terms": [30,[2,6],[3]],
		"/[lang]/(action)/merge": [16,[2,4],[3]],
		"/[lang]/pricing": [31,[2],[3]],
		"/[lang]/(action)/protect": [17,[2,4],[3]],
		"/[lang]/result/[id]": [32,[2],[3]],
		"/[lang]/screen-recorder-online": [33,[2],[3]],
		"/[lang]/(action)/split": [18,[2,4],[3]],
		"/[lang]/uninstall": [34,[2],[3]],
		"/[lang]/(action)/unlock": [19,[2,4],[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';